<!--
 * @Author: bashuang
 * @Date: 2023-11-9
 * @Description: 关于我们页面-公司介绍
-->
<template>
  <div class="companyProfile">
    <div class="module-tit">{{ '公司简介' | sLang }}</div>
    <div class="intro-wrap">
      <div class="intro-tit">{{ '汇达（香港）保险经纪' | sLang }}</div>
      <div class="intro-con intro-con1">{{ '成立于2015年4月，秉持“立足香港、服务全球”的发展格局，致力于成为中高端客户群体最青睐、香港领先的保险综合服务平台，为个人和家庭提供多元化的综合保障及保险规划服务。' | sLang }}</div>
      <!-- <div class="intro-con">{{ '汇达香港保险经纪于2019年10月正式开始启用新职场，新职场位于香港尖沙咀核心商业区，精选坐落于维多利亚港海岸的甲级写字楼一-K11 ATELIER，致力于为客户提供更加高端、优质及便捷的服务。' | sLang }}</div> -->
      <myImgLazy class="intro-photo" :src="require('@/assets/img/aboutUs/intro_photo.jpg')"  alt="匯達(香港)保險經紀" title="匯達(香港)保險經紀"></myImgLazy>
    </div>
  </div>
</template>

<script>
import myImgLazy from "@/components/myImgLazy/index.vue"
export default {
  name: 'companyProfile',
  props: {
    title: '',
  },
  components: {
    myImgLazy
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style lang='scss' scoped>
.companyProfile {
  width: 100%;
  .module-tit{
    font-size: 0.22rem;
    // font-family: HiraginoSansGB-W5, HiraginoSansGB;
    font-weight: 500;
    color: #333333;
    margin-bottom: 0.30rem
  }
  .intro-wrap{
    padding: 0.31rem 4.50rem 0.31rem 0.24rem;
    width: 7.80rem;
    height: 3.55rem;
    background: #F7F8FA;
    position: relative;
    .intro-tit{
      height: 0.26rem;
      font-size: 0.14rem;
      // font-family: HiraginoSansGB-W6, HiraginoSansGB;
      font-weight: 600;
      color: #333333;
      position: relative;
      line-height: 0.18rem;
      &::after{
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0.16rem;
        height: 0.02rem;
        background: #333333;
      }
    }
    .intro-con{
      font-size: 0.14rem;
      // font-family: HiraginoSansGB-W3, HiraginoSansGB;
      font-weight: normal;
      color: #333333;
      line-height: 0.26rem;
      text-align: justify;
      transform: scale(0.8);
      transform-origin: left
    }
    .intro-con1{
      padding: 0 0 0.3rem;
    }
    .intro-photo{
      position: absolute;
      width: 4.60rem;
      right: -0.4rem;
      top: 0.30rem;
    }
  }
}
</style>