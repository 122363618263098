<!--
 * @Author: diaowangtao
 * @Date: 2020-08-17 14:45:45
 * @Description: 首页
-->
<template>
  <div class="home-index">
    <div class="banner" v-lazy:background-image="require('@/assets/img/index/banner.jpeg')">
      <img :src="require('@/assets/img/index/bannerText.jpg')" alt="匯達(香港)保險經紀" title="匯達(香港)保險經紀" />
      <div class="dataShow-box w1200">
        <div class="dataShow" v-for="(item, index) in bannerDataShow">
          <div class="dataTop" :class="!item.unit ? 'dataTop textTop':'dataTop'">{{ numberAdd(item,index) }}<span v-show="item.price>0" :class="item.unit != '+' ? 'unit':''">{{ item.unit | sLang }}</span></div>
          <div class="dataBottom">{{ item.tit | sLang }}</div>
        </div>
      </div>
    </div>
    <!-- 服务模式   start -->
    <div class="companyIntro" v-lazy:background-image="require('@/assets/img/index/jjbg.jpeg')">
      <div class="introTitle">
        <p><img :src="require('@/assets/img/index/tit_tg_jj.png')" alt="" /></p>
        <span>{{ '服务模式' | sLang }}</span>
      </div>
      <div class="intro-tit">{{ '汇达经纪' | sLang }}</div>
      <div class="introCon" v-lazy:background-image="require('@/assets/img/index/jjconbg.png')">
        <div class="con-text">{{ '家庭风险管理综合服务商' | sLang }}</div>
        <div class="con-text">{{ '全方位一体化赋能平台' | sLang }}</div>
        <div class="con-text">{{ '增量-增收' | sLang }}</div>
        <div class="con-text">{{ '增效-增值' | sLang }}</div>
        <div class="con-text">{{ '个人' | sLang }}</div>
        <div class="con-text">{{ '渠道' | sLang }}</div>
        <div class="con-text">{{ '投顾' | sLang }}</div>
      </div>
    </div>
    <div class="type-box">
      <div class="type-left left">
        <div :class="typeActive==0 ? 'type-active':''" @mouseenter="onMouseEnter('typeActive', 0)">
          <p>{{ '个人' | sLang }}</p>
          <span>PERSON</span>
        </div>
        <div :class="typeActive==1 ? 'type-active':''" @mouseenter="onMouseEnter('typeActive', 1)">
          <p>{{ '渠道' | sLang }}</p>
          <span>CHANNEL</span>
        </div>
      </div>
      <div class="type-right right" @mouseleave="onMouseLeave('typeNameActive', -1)">
        <div class="left" v-for="(item, index) in typeActiveList[typeActive].titList"  @mouseenter="onMouseEnter('typeNameActive', index)" v-lazy:background-image="itemImg(typeActiveList[typeActive].imgList[index])">
          <div :class="typeNameActive==index?'type-name active':'type-name'">{{ item | sLang }}</div>
        </div>
      </div>
    </div>
    <!-- 服务模式   end -->
    <!-- 业务范围   start -->
    <div class="pusher-wrap" id="anchor_2">
      <div class="introTitle">
        <p>
          <img :src="require('@/assets/img/index/business_scope.png')" alt="" />
        </p>
        <span v-html="$options.filters.sLang('业务范围')"></span>
      </div>
      <div class="w1200 yewu-text">
        {{
          '汇达（香港）保险经纪成立于2015年4月，秉持“立足香港、服务全球”的发展格局，致力于成为中高端客户群体最青睐、香港领先的保险综合服务平台，为个人和家庭提供多元化的综合保障及金融规划服务。'
            | sLang
        }}
      </div>
      <ul class="w1200 pusher">
        <div class="tab-wrap" :class="scopeActive == 0 ? 'tab-wrap-0':scopeActive == 2 ? 'tab-wrap-2':''">
          <div
            :class="scopeActive == index ? 'tab-menu is-active': 'tab-menu'"
            v-for="(item,index) in scopeShowData"
            :key="item.tit"
            @mouseenter="onMouseEnter('scopeActive', index)"
            @mouseleave="onMouseLeave('scopeActive', -1)"
            @click="onMouseEnter('scopeActive', index)"
          >
          {{ item.tit | sLang }}
        </div>
        </div>
        <div class="type-right pusher-type" @mouseleave="onMouseLeave('scopeActive', -1)">
          <div class="left pusher-item" @mouseenter="onMouseEnter('scopeActive', index)" v-for="(item,index) in scopeShowData" :key="item.tit">
            <div :class="scopeActive != index ? 'scope-type' : 'scope-type scope-active' ">
              <div class="type-tit">{{ item.tit | sLang }}</div>
              <div class="type-text" v-show='scopeActive == index'>
                {{item.text | sLang }}
              </div>
              <div class="type-icon" v-show="scopeActive == index">
                <span v-for="(el) in item.mark" :key="el">{{ el | sLang }}</span>
              </div>
            </div>
          </div>
        </div>
      </ul>
    </div>
    <!-- 业务范围   end -->
    <!-- 核心驱动   start -->
    <div class="footer-max-bg">
      <div class="advantage" id="anchor_2">
      <div class="introTitle">
        <p>
          <img :src="require('@/assets/img/index/Service_mode.png')" alt="" />
        </p>
        <span v-html="$options.filters.sLang('核心驱动')"></span>
        <div class="mode-icon-wrap">
          <span class="mode-icon">{{ '创新驱动' | sLang }}</span>
          <span class="mode-icon">{{ '科技引领' | sLang }}</span>
          <span class="mode-icon">{{ '价值创造' | sLang }}</span>
          <span class="mode-icon">{{ '协同共赢' | sLang }}</span>
        </div>
      </div>
      <ul class="advantageCon-wrap w1200" @mouseleave="onMouseLeave($event)">
        <li
          class="advantageCon"
          :style="{ width: showWith[i] }"
          @mouseenter="onMouseEnter($event, i, 'mode')"
          v-for="(item, i) in modeItemData"
          :key="item.tit"
          ref="mode"
        >
          <div class="con-tit">{{ item.tit | sLang }}</div>
          <div class="con-text">{{ item.text | sLang }}</div>
          <div class="con-labelList" v-for="el in item.labelList" :key="el">
            {{ el }}
          </div>
        </li>
      </ul>
    </div>
    <!-- 核心驱动   end -->
    <my-foot @changePage="changePage" id="anchor_3" />
    </div>
    
  </div>
</template>

<script>
import '../../assets/css/home/index.scss'
import myFoot from '@/components/myFoot/index.vue'
// import { getIssuerList } from '@/web/home'
import { setTimeout, clearTimeout } from 'timers'
export default {
  name: 'index',
  data() {
    return {
      issuerList: [],
      iconList: [],
      // banner图中间显示数据
      bannerDataShow: [
        { tit: '累计服务客户', price: 0, priceTop: 16000, unit: '+', timer: null, timeStep: 30, numStep: 1 },
        { tit: '优选合作保险公司', price: 0, priceTop: 25, unit: '+', timer: null, timeStep: 90, numStep: 1 },
        { tit: '累计上线保险产品', price: 0, priceTop: 90, unit: '+', timer: null, timeStep: 25, numStep: 1 },
        { tit: '专业保险服务', price: 0, priceTop: 10, unit: '年', timer: null, timeStep: 210, numStep: 1 },
        { tit: '产品背后顶尖投资团队投资全球', price: '链接全球' },
      ],
      activeName: 0,
      // 业务范围模块展示数据
      scopeShowData: [
        { tit: '健康保障', text: '全面的健康保障，助您无论面对大小疾病，都从容应对。', mark: ['危疾保障', '医疗保障', '意外保障'] },
        { tit: '人寿保障', text: '周全的人寿保障，助您守护挚爱、家业长青、安享精彩人生。', mark: ['分红终寿', '两全寿险', '定期寿险', '万用寿险'] },
        { tit: '储蓄保障', text: '充裕的储蓄保障，助您规划财富蓝图，把握未来，成就每个人生里程碑。', mark: ['储蓄计划', '退休计划', '年金计划'] }
      ],
      // 核心驱动模块展示数据
      modeItemData: [
        {
          tit: '产品',
          text: '打造匠心严选、买方智选的保险产品体系，通过严选合作渠道，形成有市场竞争力保司库。',
          labelList: ['匠心严选', '买方智选'],
        },
        {
          tit: '科技',
          text: '贯彻“科技+服务”理念，充分考虑市场化业务模式，通过搭建个人及渠道服务平台满足市场化业务需求。',
          labelList: ['科技引领', '全面赋能'],
        },
        {
          tit: '投顾',
          text: '以客户为中心，着力提升客户全流程服务体验，全力打造具有特色的投前、投中、投后全方位顾问服务体系。',
          labelList: ['投顾视角', '专属服务'],
        },
      ],
      showWith: ['44%', '25%', '25%'],
      typeActive: 0, // 个人和渠道的显示
      typeNameActive: -1, // 个人渠道的三个图片
      scopeActive: -1, // 业务范围
      tiemr: null, // 计时器
      // 服务模式个人-渠道动态数据
      typeActiveList: [
        { titList: ['创富传承', '健康守护', '品质生活'], imgList: ['type_right_1.png', 'type_right_2.png', 'type_right_3.png'] },
        { titList: ['产品货架', '科技赋能', '品牌生态'], imgList: ['type_right_4.png', 'type_right_5.png', 'type_right_6.png'] }
      ]
    }
  },
  components: {
    myFoot,
  },
  // created() {
  //   getIssuerList({ pageNo: 1, pageSize: 20 }).then((res) => {
  //     this.issuerList = res.list
  //     res.list.forEach((el, i) => {
  //       this.getHeight('mini-icon-', i)
  //     })
  //   })
  // },

  methods: {
    // banner位置数字递增
    numberAdd(item, index) {
      let data = this.bannerDataShow[index];
      if (item.price === 0) {
        if (this.bannerDataShow[index].tiemr) {
          return
        }
        this.bannerDataShow[index].tiemr = setInterval(() => {
          this.$set(data, 'price', data.price + data.numStep)
          if (index === 0) {
            if (data.price >= 15990) {
              this.$set(data, 'numStep', 1)
            } else if (data.price >= 15900) {
              this.$set(data, 'numStep', 10)
            } else if (data.price >= 15000) {
              this.$set(data, 'numStep', 100)
            } else if (data.price >= 1000) {
              this.$set(data, 'numStep', 1000)
            } else if (data.price >= 100) {
              this.$set(data, 'numStep', 100)
            } else if (data.price >= 10) {
              this.$set(data, 'numStep', 10)
            }
          }
          if (data.price === data.priceTop) {
            clearInterval(this.bannerDataShow[index].tiemr)
            return this.bannerDataShow[index].price
          }
        }, data.timeStep)
      } else {
        return item.price
      }
      // if(data.price === data.priceTop){
      //   clearInterval(this.tiemr)
      // }
    },
    // 核心驱动-动态样式
    onMouseEnter(params, i, type) {
      if (type === 'mode') {
        this.showWith = ['25%', '25%', '25%']
        this.showWith[i] = '44%'
      } else {
        console.log('222222222', params, i)
        this[params] = i
      }
    },
    onMouseLeave(params, i, type) {
      // this.showWith = '50%';
      this[params] = i
    },

    typeNameActiveLeave() {
      this.typeNameActive = 0
    },
    changePage(i) {
      this.$store.commit('changePage', i)
    },
    // 个人-渠道图片替换
    itemImg(id) {
      return require(`@/assets/img/index/${id}`)
    },
  },
}
</script>
